html {
    scroll-behavior: smooth;
}

/* Whitelist */
.auth, .loading {
    opacity: 1;
}

body {
    -webkit-font-smoothing: antialiased;
    font-smooth: antialiased;
    transition: border-color 0.3s ease;
    transition: background-color 0.3s ease;
    transition: color 0.3s ease;
    transition: fill 0.3s ease;
    transition: opacity 3s ease;
    transition: height 0.3s linear;
}

/*
 * Remove autofill styling
 */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:selected,
input:-internal-autofill-selected,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: none !important;
    /*
    -webkit-text-fill-color: transparent;
     */
    -webkit-box-shadow: none;
    background-color: transparent !important;
}

button, a, input, select, textarea {
    &:focus, &:active {
        outline: none;
    }
}

a, button {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    outline: none !important;
}

strong {
    @apply font-semibold;
}

svg {
    height: 100%;
    width: auto;

    &:not(.no-current) {
        fill: currentColor;
        stroke: currentColor;
    }
}

.turbo-progress-bar {
    height: 5px;
    @apply bg-green;
}

.drop-shadow {
    filter: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.2)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1));
}

@layer utilities {
    .snap {
        scroll-snap-type: var(--scroll-snap-direction) var(--scroll-snap-constraint);
    }

    .snap-y {
        --scroll-snap-direction: y;
    }

    .snap-x {
        --scroll-snap-direction: x;
    }

    .snap-mandatory {
        --scroll-snap-constraint: mandatory;
    }

    .snap-start {
        scroll-snap-align: start;
    }
}

@keyframes tonext {
  75% {
    left: 0;
  }
  95% {
    left: 100%;
  }
  98% {
    left: 100%;
  }
  99% {
    left: 0;
  }
}

@keyframes tostart {
  75% {
    left: 0;
  }
  95% {
    left: -300%;
  }
  98% {
    left: -300%;
  }
  99% {
    left: 0;
  }
}

@keyframes snap {
  96% {
    scroll-snap-align: center;
  }
  97% {
    scroll-snap-align: none;
  }
  99% {
    scroll-snap-align: none;
  }
  100% {
    scroll-snap-align: center;
  }
}

* {
  box-sizing: border-box;
  scrollbar-color: transparent transparent; /* thumb and track color */
  scrollbar-width: 0px;
}

*::-webkit-scrollbar {
  width: 0;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background: transparent;
  border: none;
}

* {
  -ms-overflow-style: none;
}
