body.with-mobile-nav {
  height: 100%;
  overflow-y: hidden;
}

/*
 * These classes implement the mobile menu opening
 */
.mobile-nav .submenu {
  clear: both;
  max-height: 0;
  overflow-y: hidden;
  transition: max-height 0.2s ease-out;
}

.mobile-nav .menu-btn {
  display: none;
}

.mobile-nav .menu-btn:checked ~ .menu {
  max-height: 999px;
}

.mobile-nav .menu-btn:checked ~ label {
  svg.chevron {
    @apply rotate-180 transform transition-transform;
  }
}

.mobile-nav .menu-btn:checked ~ .submenu {
  max-height: 999px;
}

.mobile-nav label:focus {
  @apply text-green;
}

/*
 * These classes support dynamically showing menubar based on scroll position
 */

nav {
  &.hide {
    position: fixed;

    animation-name: slide_out;
    animation-duration: 0.7s;
    animation-timing-function: ease-out;

    margin-top: -100%;

    &:before {
      @apply opacity-100;
    }
  }

  &.show {
    position: fixed;

    animation-name: slide_in;
    animation-duration: 0.7s;
    animation-timing-function: ease-out;

    margin-top: 0;

    &:before {
      @apply opacity-100;
    }
  }
}

@keyframes slide_in {
  from {
    margin-top: -100%;
  }

  to {
    margin-top: 0;
  }
}

@keyframes slide_out {
  from {
    margin-top: 0;
  }

  to {
    margin-top: -100%;
  }
}
