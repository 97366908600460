.typography {
  .h0,
  h1,
  h2,
  h3 {
    @apply font-heading;
  }

  p + p {
    padding-top: 1em;
  }

  /*
     * Mobile
     */
  .h0,
  h1.h0 {
    @apply text-58 font-bold;
    letter-spacing: -1px;
  }

  h1 {
    @apply text-44 font-bold;
  }

  p + h2 {
    padding-top: 0.875em;
  }

  h2 {
    @apply text-32 font-bold;
  }

  h2 + p {
    padding-top: 0.5em;
  }

  h2 + h3 {
    padding-top: 0.545em;
  }

  h3 {
    @apply text-18 font-bold;
  }

  p + h3 {
    padding-top: 1.182em;
  }

  h3 + p {
    padding-top: 0.25em;
  }

  p {
    @apply text-base;
  }

  p + ul {
    @apply list-disc pb-8 pl-8 text-base;

    li {
      padding-top: 1em;
    }
  }

  /*
     * Desktop
     */
  @media screen(lg) {
    .h0,
    h1.h0 {
      @apply text-64 font-bold;
    }

    h1 {
      @apply text-64 font-bold;
    }

    p + h2 {
      padding-top: 0.5em;
    }

    h2 {
      @apply text-52 font-bold;
    }

    h2 + p {
      padding-top: 0.65em;
    }

    h2 + h3 {
      padding-top: 0.5em;
    }

    p + h3 {
      padding-top: 0.85em;
    }

    h3 {
      @apply text-36 font-bold;
    }

    h3 + p {
      padding-top: 0.2em;
    }

    p {
      @apply text-18;
    }

    p + ul {
      @apply list-disc pb-8 pl-8 text-18;

      li {
        padding-top: 1em;
      }
    }
  }

  /*
     * Make sure we can override paragraph style when it comes from Markdown
     */
  /*
    .text-18 > p {
        @apply text-18;
    }

    .leading-relaxed > p {
        @apply leading-relaxed;
    }

    .text-18 > p {
        @apply text-18;
    }

    .text-24 > p {
        @apply text-24;
    }
     */

  h1 span {
    display: inline-block;
    position: relative;
    z-index: 10;

    &:before {
      content: "";
      z-index: -1;
      position: absolute;
      width: 100%;
      height: 10px;
      bottom: 17px;
      opacity: 0.8;
      @apply bg-green;
    }
  }

  h1.h0 span:before {
    bottom: 17px;
    height: 10px;
    left: 4px;

    @media screen(md) {
      bottom: 25px;
      height: 12px;
      left: 6px;
    }
    width: 98%;
    opacity: 0.7;
  }

  h1.h0 span.yellow:before {
    @apply bg-yellow;
    opacity: 0.9;
  }

  .strong-green strong {
    @apply text-green;
  }
}
