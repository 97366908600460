@font-face {
    font-family: 'AvenirNextRoundedPro';
    src: url('/fonts/AvenirNextRoundedStd-Reg.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'AvenirNextRoundedPro';
    src: url('/fonts/AvenirNextRoundedStd-Med.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'AvenirNextRoundedPro';
    src: url('/fonts/AvenirNextRoundedStd-Demi.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'AvenirNext';
    src: url('/fonts/AvenirNextLTPro-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}
